<!--
NOTES:
- Align prod rows quantities and totals
- prices align right
- only show headers for first row
- only show inline editing if logged in

-->
<template>
  <div class="flex flex-grow flex-col bg-gray-100 w-full h-full justify-center">
    <Nuxt />
    <transition name="fade">
      <LoginPanel
        v-show="showSignin"
        ref="login"
        :inline="isInline"
        @close="showSignin = false"
      />
    </transition>
  </div>
</template>

<script>
import rest from '~/utils/rest'

export default {
  data() {
    return {
      showSignin: false,
      isInline: false
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$route.name
      }
    }
  },
  created() {
    this.$nuxt.$on('setLogin', ($event) => {
      if ($event.inline) {
        this.isInline = true
      }
      this.setLogin($event.show)
    })
  },
  mounted() {
    rest.setDomainRoot()
  },
  methods: {
    setLogin($ev) {
      store.SET_SHOW_SIGNIN(true)
    }
  }
}

</script>
